html, body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

/* You can add global styles to this file, and also import other style files */
body {
  --g-primary:  #FF5756;
  --g-primary-contrast: #FFFFFF;
  --g-warning: #EF426F;

  --g-base: #F4F3F1;
  --nav-web-primary: black;
  --nav-web-secondary: white;
  
  --g-font-headline: sans-serif;
  --g-font-body: "Helvetica", sans-serif;
  --g-font-accent: "Caveat";
  
  --g-global-corner-radius: 8px;

  background-color: var(--g-base);

  // Remove iphone tap color https://stackoverflow.com/questions/8990442/is-there-a-way-to-disable-the-flash-blink-on-the-ipad-iphone-when-an-onclick-is
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--g-font-headline);
}

p, li, span, div {
  font-family: var(--g-font-body);
  font-size: 17px;
}
p {
  line-height: 24px;
}